import React from 'react';
import _ from 'lodash';
import { Button, ButtonToolbar, ControlLabel, DatePicker, Form, FormControl, FormGroup, HelpBlock, Loader, Panel } from 'rsuite';
import { NodeService } from '../services/NodeService';
import { TokenInfo } from '../services/ModelView';

// =======================================================================================================
export interface ShortTimeTokenProps {
    onSubmitComplete: (success: boolean) => void;
}


// ------------------------------------------------------------------------------------------------------
export interface ShortTimeTokenState {
    formValue: TokenInfo;
    loading: boolean;
}
// ------------------------------------------------------------------------------------------------------
export default class ShortTimeToken extends React.Component<ShortTimeTokenProps, ShortTimeTokenState> {
    private NodeService: NodeService;
    constructor(props: ShortTimeTokenProps) {
        super(props);
        this.state =
        {
            formValue: {
                issuedTo: '',
                validDate: undefined,
                id: 0
            },
            loading: false
        }
        this.NodeService = new NodeService();
    }
    // --------------------------------------------------------------------------------------------------
    issueToken = async () => {
        if (this.state.formValue.issuedTo === null || this.state.formValue.issuedTo === undefined || this.state.formValue.issuedTo.length === 0 ||
            this.state.formValue.email === null || this.state.formValue.email === undefined || this.state.formValue.email.length === 0 ||
            this.state.formValue.validDate === null || this.state.formValue.validDate === undefined)
            return;
        console.log(this.state.formValue);
        this.setState({ loading: true })
        var res = await this.NodeService.issueOneTimeToken(this.state.formValue);
        this.setState({ loading: false })
        if (res)
            this.props.onSubmitComplete(true);
    }
    // --------------------------------------------------------------------------------------------------

    changeDate = (date: Date) => {

        var data = this.state.formValue;
        _.set(data, "validDate", date);
        this.setState({ formValue: data });
    }

    // --------------------------------------------------------------------------------------------------
    render() {
        return <Panel header="Issue a new Token"><Form layout="horizontal" onChange={(formValue) => {
            this.setState({ formValue: formValue as TokenInfo });
        }}>
            <FormGroup>
                <ControlLabel>Name</ControlLabel>
                <FormControl name="issuedTo" />
                <HelpBlock tooltip>Required</HelpBlock>
            </FormGroup>
            <FormGroup>
                <ControlLabel>Email</ControlLabel>
                <FormControl name="email" type="email" />
                <HelpBlock tooltip>Required</HelpBlock>
            </FormGroup>
            <FormGroup>
                <ControlLabel>Phone</ControlLabel>
                <FormControl name="phone" />
            </FormGroup>
            <FormGroup>
                <ControlLabel>Valid From</ControlLabel>
                <DatePicker oneTap onSelect={this.changeDate} name="validDate" />
                <HelpBlock tooltip>Required</HelpBlock>
            </FormGroup>
            <FormGroup style={{ textAlign: "right" }}>
                <ButtonToolbar>
                    {this.state.loading ? <Loader /> : <Button appearance="primary" onClick={this.issueToken}>Add</Button>}
                </ButtonToolbar>
            </FormGroup>
        </Form>
        </Panel>
    }
}
// =======================================================================================================