import React from 'react';
import _ from 'lodash';
import { format } from 'date-fns'
import './NavalFleetManager.less';
import { Panel, Navbar, Nav, FormGroup, Input, IconButton, Icon, ControlLabel, Form, Divider, Table, FormControl, Modal, Button, Loader, Pagination, HelpBlock, TableCellProps } from 'rsuite';
import { NodeService } from '../services/NodeService';
import { HolderInfo, CertificateInfo, CurriculumSummary, TokenInfo, InternalOfficer, Roles } from '../services/ModelView';
import QRCode from 'qrcode.react';
import { Certificate, generateQR } from '../lib/Certificates';
import { CrewmanService } from '../services/CrewmanService';
import ShortTimeToken from './ShortTimeTokenForm';
import { AxiosError } from 'axios';

// =======================================================================================================
class TextField extends React.PureComponent<any, any> {
    render() {
        const { name, label, accepter, ...props } = this.props;
        return (
            <FormGroup>
                <ControlLabel >{label} </ControlLabel>
                <FormControl disabled name={name} accepter={accepter} style={{ maxWidth: "240px" }} {...props} />
            </FormGroup>
        );
    }
}

export interface NavalFleetManagerProps {
    height: number;
    role: string | undefined;
}

export interface TokenProps {
    email: string;
    id: number;
    isValid: boolean;
    issuedTo: string;
    phone: any;
    revoked: boolean;
    validFrom: string;
    validTo: string;
}

export interface NavalFleetManagerState {
    holder: HolderInfo | undefined,
    email: string
    crewId: string;
    name: string;
    holderCertificates: CertificateInfo[] | undefined
    showQRCode: boolean;
    holderChallenge: string,
    showCertificate: boolean,
    selectedCertificate: CertificateInfo | undefined
    selectedCertificateImage: string | undefined
    selectedCertificateCurriculum: CurriculumSummary | undefined;
    tokens: TokenProps[]
    searchingUser: boolean,
    searchingCerts: boolean,
    activePage: number,
    totalCertificates: number;
    showTokenModal: boolean;
    loadingTokens: boolean;
    showModalAddOfficers: boolean;
    internalOfficer: InternalOfficer
    modalError: string;
    loading: boolean;
    showSearchName: boolean;
    nameSearch: NameSearch[];
}

export interface NameSearch {
    email: string;
    firstName: string;
    lastName: string
}

export default class NavalFleetManager extends React.Component<NavalFleetManagerProps, NavalFleetManagerState> {

    private NodeService: NodeService;
    constructor(props: any) {
        super(props);
        this.state =
        {
            selectedCertificateImage: undefined,
            selectedCertificateCurriculum: undefined,
            email: "",
            crewId: "",
            name: "",
            holder: undefined,
            holderCertificates: undefined,
            showQRCode: false,
            holderChallenge: "",
            showCertificate: false,
            selectedCertificate: undefined,
            searchingUser: false,
            activePage: 1,
            totalCertificates: 0,
            tokens: new Array<TokenProps>(),
            showTokenModal: false,
            loadingTokens: false,
            showModalAddOfficers: false,
            showSearchName: false,
            modalError: '',
            loading: false,
            searchingCerts: false,
            internalOfficer: {
                name: '',
                email: ''
            },
            nameSearch: new Array<NameSearch>()
        }
        this.NodeService = new NodeService();
    }

    // --------------------------------------------------------------------------------------------------

    componentDidMount() {
        if (this.props.role === Roles.NFManager.toString())
            this.getTokens();
    }
    // --------------------------------------------------------------------------------------------------

    async getTokens() {
        this.setState({ loadingTokens: true })
        var tokens = await this.NodeService.getOneTimeTokens();
        if (tokens !== undefined)
            this.setState({ tokens: tokens, loadingTokens: false })
        else
            this.setState({ loadingTokens: false })
    }
    // --------------------------------------------------------------------------------------------------

    render() {
        return <div style={{ display: "flex" }} className="ca">
            <Panel shaded bodyFill style={{ flex: 1, height: this.props.height - 20, margin: 10, overflow: "auto" }}>
                {this.props.role === Roles.NFManager.toString() ? this.renderAddOfficers() : null}
                {this.renderMainPanel()}
                {this.renderQRCode()}
                {this.renderModalAddOfficers()}
                {this.renderShortTokenForm()}
                {this.renderModalSearchName()}
            </Panel>

            <Panel className="ca-certificate" shaded bodyFill style={{ flex: 1, margin: 10, height: this.props.height - 20 }}>
                {this.renderHolderCertificates(this.props.height - 20)}
                {this.renderCertificate()}
            </Panel>

        </div>
    }
    // --------------------------------------------------------------------------------------------------

    renderAddOfficers() {
        return <>
            <Navbar bordered style={{ margin: 20 }} appearance={"default"}>
                <Navbar.Header>
                    <div style={{
                        lineHeight: "56px",
                        paddingLeft: 20,
                        fontSize: "120%",
                        fontWeight: "bolder"
                    }} >Head of Crewing</div>
                </Navbar.Header>
                <Navbar.Body>
                    <Nav pullRight >
                        <Nav.Item icon={<Icon icon="user-plus" />} onClick={() => { this.setState({ showModalAddOfficers: true, modalError: '' }) }}>Register Internal Crewing Officers</Nav.Item>
                    </Nav>
                </Navbar.Body>
            </Navbar>
        </>
    }
    // --------------------------------------------------------------------------------------------------
    renderModalAddOfficers() {
        return (
            <Modal show={this.state.showModalAddOfficers} onHide={(c) => { this.setState({ showModalAddOfficers: false }) }}>
                <Modal.Header>
                    <Modal.Title>New Internal Officer</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onChange={(formValue) => {
                        this.setState({ internalOfficer: formValue as InternalOfficer });
                    }}>
                        <FormGroup>
                            <ControlLabel>Name</ControlLabel>
                            <FormControl name="name" />
                            <HelpBlock tooltip>Required</HelpBlock>
                        </FormGroup>
                        <FormGroup>
                            <ControlLabel>Email</ControlLabel>
                            <FormControl name="email" type="email" />
                            <HelpBlock tooltip>Required</HelpBlock>
                        </FormGroup>
                        <div style={{ color: "red" }}>
                            {this.state.modalError}
                        </div>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    {this.state.loading ? <Loader /> :
                        <Button onClick={this.addInternalOfficer} appearance="primary">Add</Button>}
                </Modal.Footer>

            </Modal>
        );
    }
    // --------------------------------------------------------------------------------------------------
    searchUserEmail = async (email: any) => {
        await this.setState({ email: email })
        await this.setState({ showSearchName: false, modalError: '', loading: false })
        await this.searchUserByEmail();
    }
    // --------------------------------------------------------------------------------------------------
    getNames = async () => {

        try {
            var name = this.state.name;
            this.setState({ searchingUser: true, activePage: 1, totalCertificates: 0, holderCertificates: undefined, email: '', crewId: '', })
            var names = await this.NodeService.getName(name);
            await this.setState({ searchingUser: false })
            await this.setState({ nameSearch: names })
            await this.setState({ showSearchName: true, modalError: '' })
        }
        catch (error) {
            console.error(error)
            this.setState({ email: '', crewId: '', name: '', searchingUser: false })
        }
    }
    // --------------------------------------------------------------------------------------------------
    renderModalSearchName() {
        return (
            <Modal show={this.state.showSearchName} onHide={(c) => { this.setState({ showSearchName: false }) }}>
                <Modal.Header>
                    <Modal.Title>Seafarers</Modal.Title>
                </Modal.Header>
                <Table
                    virtualized
                    rowHeight={53}
                    height={this.props.height - 400}
                    data={this.state.nameSearch}>

                    <Table.Column width={250} align="center" resizable>
                        <Table.HeaderCell>Name</Table.HeaderCell>
                        <Table.Cell >
                            {(rowData: NameSearch) => {
                                return <div>
                                    {rowData.firstName}
                                </div>
                            }}
                        </Table.Cell>
                    </Table.Column>
                    <Table.Column width={250} align="center" resizable>
                        <Table.HeaderCell>Email</Table.HeaderCell>
                        <Table.Cell >
                            {(rowData: NameSearch) => {
                                return <div>
                                    {rowData.email}
                                </div>
                            }}
                        </Table.Cell>
                    </Table.Column>
                    <Table.Column width={60} align="center" resizable>
                        <Table.HeaderCell></Table.HeaderCell>
                        <Table.Cell >
                            {(rowData: NameSearch) => {
                                return <div>
                                    <IconButton onClick={(c) => this.searchUserEmail(rowData.email)} icon={<Icon icon="search" />} />
                                </div>
                            }}

                        </Table.Cell>
                    </Table.Column>
                </Table>
                <Modal.Footer style={{ marginTop: 20, fontSize: 10 }}>
                    The search will return the top 5 results
                </Modal.Footer>
            </Modal>
        );
    }
    // --------------------------------------------------------------------------------------------------
    clearHolderInfo() {
        var info: HolderInfo =
        {
            email: '',
            firstName: '',
            lastName: '',
            middleName: '',
            address: '',
            verified: false,
            date_of_verification: undefined,
            date_of_birth: ''
        }
        this.setState({ holder: info })
    }
    // --------------------------------------------------------------------------------------------------

    addInternalOfficer = async () => {
        if (this.state.internalOfficer?.email === undefined || this.state.internalOfficer?.email === null || this.state.internalOfficer?.name === undefined || this.state.internalOfficer?.name === undefined)
            return;
        try {
            this.setState({ modalError: '', loading: true })
            var result = await this.NodeService.addOfficer(this.state.internalOfficer.email, this.state.internalOfficer.name);
            if (result.code === 1) {
                this.setState({ modalError: result.message, loading: false })
            }
            else {
                this.setState({ showModalAddOfficers: false, loading: false });
            }
        }
        catch (ex) {
            console.error(ex);
            const err = ex as AxiosError;
            this.setState({ modalError: err?.response?.data?.message, loading: false })
        }
    }
    // --------------------------------------------------------------------------------------------------

    renderQRCode() {
        return (
            <Modal show={this.state.showQRCode} onHide={(c) => { this.setState({ showQRCode: false }) }}>
                <Modal.Body>
                    <div style={{
                        background: "white",
                        margin: "0 auto",
                        width: 296,
                        boxShadow: "0 4px 4px rgba(0, 0, 0, 0.12), 0 0 10px rgba(0, 0, 0, 0.06)",
                        padding: 20,
                        borderRadius: 20
                    }}>
                        <QRCode size={256} value={this.state.holderChallenge} />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={(c) => this.setState({ showQRCode: false })} appearance="primary">Close</Button>
                </Modal.Footer>
            </Modal>
        );
    }
    // --------------------------------------------------------------------------------------------------

    renderShortTokenForm() {
        return (
            <Modal show={this.state.showTokenModal} onHide={(c) => { this.setState({ showTokenModal: false }) }}>
                <Modal.Body>
                    <ShortTimeToken onSubmitComplete={(res) => this.onSuccessfulTokenIssuance()} />
                </Modal.Body>

            </Modal>
        );
    }
    // --------------------------------------------------------------------------------------------------

    onSuccessfulTokenIssuance(): void {
        this.setState({ showTokenModal: false });
        this.getTokens();
    }
    // --------------------------------------------------------------------------------------------------

    renderHolderCertificates(height: number) {
        return <>
            <Panel key="certificate-table" shaded bodyFill style={{ margin: 20 }}>
                <Navbar>
                    <Navbar.Header>
                        <div style={{
                            lineHeight: "56px",
                            paddingLeft: 20,
                            fontSize: "120%",
                            fontWeight: "bolder"
                        }} >Certificates</div>
                    </Navbar.Header>
                    <Navbar.Body>
                        <Nav pullRight>
                            <Nav.Item >
                                <IconButton style={{ marginTop: -5 }} onClick={(c) => this.getCertificates(this.state.activePage)} icon={<Icon icon="refresh" />}>
                                </IconButton>
                            </Nav.Item>
                        </Nav>
                    </Navbar.Body>
                </Navbar>

                <Table
                    loading={this.state.searchingCerts}
                    height={height - 130} rowHeight={46}
                    data={this.state.holderCertificates}
                    onRowClick={data => {
                        console.log(data);
                    }}
                >

                    <Table.Column flexGrow={1} align="center" sortable>
                        <Table.HeaderCell>Title</Table.HeaderCell>
                        <Table.Cell dataKey="title" />
                    </Table.Column>

                    <Table.Column flexGrow={1} align="center" sortable>
                        <Table.HeaderCell>Address</Table.HeaderCell>
                        <Table.Cell dataKey="address" />
                    </Table.Column>

                    <Table.Column flexGrow={1} align="center" sortable>
                        <Table.HeaderCell>CA</Table.HeaderCell>
                        <Table.Cell dataKey="certificate_authority" />
                    </Table.Column>

                    <Table.Column flexGrow={1} align="center" >
                        <Table.HeaderCell>Revoked</Table.HeaderCell>
                        <Table.Cell>
                            {(rowData: CertificateInfo) => {
                                return (<div> {rowData.revoked ? <Icon
                                    style={{ color: 'red' }}
                                    icon='minus-circle'
                                /> : null}
                                </div>)
                            }}
                        </Table.Cell>
                    </Table.Column>

                    <Table.Column flexGrow={1} align="center" >
                        <Table.HeaderCell>Valid</Table.HeaderCell>
                        <Table.Cell>
                            {(rowData: CertificateInfo) => {
                                return (<div>
                                    {!rowData.valid ? <Icon
                                        style={{ color: 'red' }}
                                        icon='minus-circle'
                                    /> : <Icon
                                        style={{ color: 'green' }}
                                        icon='check-circle'
                                    />}
                                </div>)

                            }}
                        </Table.Cell>
                    </Table.Column>

                    <Table.Column flexGrow={1} align="center" >
                        <Table.HeaderCell></Table.HeaderCell>
                        <Table.Cell>
                            {(rowData: CertificateInfo) => {
                                return (
                                    <IconButton style={{ marginTop: -10 }} onClick={(c) => this.viewCertificateInfo(rowData)} icon={<Icon icon="eye" />} />
                                );
                            }}

                        </Table.Cell>
                    </Table.Column>

                </Table>
                <Pagination
                    prev
                    last
                    next
                    first
                    maxButtons={6}
                    size="sm"
                    boundaryLinks={true}
                    pages={Math.ceil(this.state.totalCertificates / 10)}
                    activePage={this.state.activePage}
                    onSelect={this.handleSelect}
                />
            </Panel>
        </>
    }

    // --------------------------------------------------------------------------------------------------

    renderCertificate() {
        return (
            <Modal className="ca-certificate" style={{ width: "70%" }} show={this.state.showCertificate} onHide={(c) => { this.setState({ showCertificate: false }) }}>
                <Modal.Body>

                    <Certificate
                        width="100%"
                        height={this.props.height - 56 - 20}
                        curriculumSummary={this.state.selectedCertificateCurriculum}
                        img={this.state.selectedCertificateImage}
                        certInfo={this.state.selectedCertificate!}
                        requestHolderChallenge={this.requestHolderChallenge}
                    />

                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={(c) => this.setState({ showCertificate: false })} appearance="primary">Close</Button>
                </Modal.Footer>
            </Modal>
        );

    }
    // --------------------------------------------------------------------------------------------------

    viewCertificateInfo = async (certificate: CertificateInfo) => {

        var qr = await generateQR(certificate.address);
        var lines = await CrewmanService.getCertificateInfo(certificate.type);
        this.setState({ showCertificate: true, selectedCertificate: certificate, selectedCertificateImage: qr, selectedCertificateCurriculum: lines })
    }
    // --------------------------------------------------------------------------------------------------


    openTokenModal = () => {
        this.setState({ showTokenModal: true });
    }
    renderMainPanel() {
        var datetime = this.state.holder?.date_of_verification;
        var date = "";
        if (datetime !== undefined)
            date = format(datetime, "YYYY-MM-DD HH:mm:ss").split('T')[0]

        return <>
            {
                this.props.role !== Roles.NFManager.toString() ? null : <Panel shaded bodyFill style={{ background: "white", margin: 20 }}>
                    <Navbar >
                        <Navbar.Header>
                            <div style={{
                                background: "transparent",
                                lineHeight: "56px",
                                paddingLeft: 20,
                                fontSize: "120%",
                                fontWeight: "bolder"
                            }} >Granted Access Tokens to External COs</div>
                        </Navbar.Header>

                        <Navbar.Body>
                            <Nav pullRight>
                                <Nav.Item >
                                    <IconButton onClick={(c) => this.openTokenModal()} style={{ marginTop: -5, background: "transparent" }} icon={<Icon icon="plus" style={{ background: "transparent" }} />}>
                                        Issue Token
                                    </IconButton>
                                    <IconButton style={{ marginTop: -5, background: "transparent" }} onClick={(c) => this.getTokens()} icon={<Icon icon="refresh" />}>
                                    </IconButton>
                                </Nav.Item>
                            </Nav>
                        </Navbar.Body>
                    </Navbar>
                    <Table
                        loading={this.state.loadingTokens}
                        height={400}
                        data={this.state.tokens}
                        onRowClick={data => {
                            console.log(data);
                        }}
                    >
                        <Table.Column width={100} align="center" resizable>
                            <Table.HeaderCell>Name</Table.HeaderCell>
                            <Table.Cell >
                                {(rowData: TokenProps) => {
                                    return (
                                        <div>
                                            {rowData.issuedTo.toString()}
                                        </div>
                                    );
                                }}
                            </Table.Cell>
                        </Table.Column>

                        <Table.Column width={200} align="center" resizable>
                            <Table.HeaderCell>Email</Table.HeaderCell>
                            <Table.Cell >
                                {(rowData: TokenProps) => {
                                    return (
                                        <div>
                                            {rowData.email.toString()}
                                        </div>
                                    );
                                }}
                            </Table.Cell>
                        </Table.Column>

                        <Table.Column width={100} align="center" resizable>
                            <Table.HeaderCell>Phone</Table.HeaderCell>
                            <Table.Cell >
                                {(rowData: TokenProps) => {
                                    return (
                                        <div>
                                            {rowData.phone === "undefined" || rowData.phone === undefined || rowData.phone === null ? "-" : rowData.phone.toString()}
                                        </div>
                                    );
                                }}
                            </Table.Cell>
                        </Table.Column>
                        <Table.Column width={90} align="center" resizable>
                            <Table.HeaderCell>Valid From</Table.HeaderCell>
                            <Table.Cell >
                                {(rowData: TokenProps) => {
                                    return (
                                        <div>
                                            {rowData.validFrom.toString().split('T')[0]}
                                        </div>
                                    );
                                }}
                            </Table.Cell>
                        </Table.Column>
                        <Table.Column width={90} align="center" resizable>
                            <Table.HeaderCell>Valid To</Table.HeaderCell>
                            <Table.Cell >
                                {(rowData: TokenProps) => {
                                    return (
                                        <div>
                                            {rowData.validTo.toString().split('T')[0]}
                                        </div>
                                    );
                                }}
                            </Table.Cell>
                        </Table.Column>
                        <Table.Column width={50} align="center" resizable>
                            <Table.HeaderCell>Valid</Table.HeaderCell>
                            <Table.Cell>
                                {(rowData: TokenInfo) => {
                                    return (<div>
                                        {!rowData.isValid ? <Icon
                                            style={{ color: 'red' }}
                                            icon='minus-circle'
                                        /> : <Icon
                                            style={{ color: 'green' }}
                                            icon='check-circle'
                                        />}
                                    </div>)

                                }}
                            </Table.Cell>
                        </Table.Column>
                        <Table.Column width={150} align="center" resizable>
                            <Table.HeaderCell></Table.HeaderCell>
                            <Table.Cell>
                                {(rowData: TokenInfo) => {
                                    return (
                                        <div> {!rowData.revoked && rowData.isValid ? <Button onClick={(c) => this.revokeToken(rowData.id)} appearance="primary" style={{ marginTop: -10, marginRight: 20 }}> Press To Revoke </Button> : null}
                                        </div>
                                    );
                                }}
                            </Table.Cell>
                        </Table.Column>
                    </Table>
                </Panel >
            }
            <Panel shaded bodyFill style={{ background: "white", margin: 20 }}>
                <Navbar>
                    <Navbar.Header>
                        <div style={{
                            lineHeight: "56px",
                            paddingLeft: 20,
                            fontSize: "120%",
                            fontWeight: "bolder"
                        }} >Search a Holder</div>
                    </Navbar.Header>
                    <Navbar.Body>

                    </Navbar.Body>
                </Navbar>
                <Form layout="horizontal">
                    <FormGroup style={{ margin: 10 }}>
                        <ControlLabel >Email </ControlLabel>
                        <Input value={this.state.email} onChange={(email) => this.setState({ email })} style={{ width: "200px", marginRight: 20 }} />
                        {this.state.searchingUser ? null : <IconButton onClick={this.searchUserByEmail} icon={<Icon icon="search" />} />}
                    </FormGroup>
                    <FormGroup style={{ margin: 10 }}>
                        <ControlLabel>CrewId</ControlLabel>
                        <Input value={this.state.crewId} onChange={(crewId) => this.setState({ crewId })} style={{ width: "200px", marginRight: 20 }} />
                        {this.state.searchingUser ? null : <IconButton onClick={this.searchUserByCrewId} icon={<Icon icon="search" />} />}
                    </FormGroup>
                    <FormGroup style={{ margin: 10 }}>
                        <ControlLabel>Name</ControlLabel>
                        <Input value={this.state.name} onChange={(name) => this.setState({ name })} style={{ width: "200px", marginRight: 20 }} />
                        {this.state.searchingUser ? null : <IconButton onClick={() => { this.getNames() }} icon={<Icon icon="search" />} />}
                    </FormGroup>
                </Form>
            </Panel >
            <Panel key="certificate-table" shaded bodyFill style={{ background: "white", margin: 20 }}>
                <Navbar>
                    <Navbar.Header>
                        <div style={{
                            lineHeight: "56px",
                            paddingLeft: 20,
                            fontSize: "120%",
                            fontWeight: "bolder"
                        }} >Holder Details</div>
                    </Navbar.Header>
                    <Navbar.Body>
                        <Nav pullRight>
                            <Nav.Item >
                                <IconButton style={{ marginTop: -5 }} onClick={(c) => this.getUser()} icon={<Icon icon="refresh" />}>
                                </IconButton>
                            </Nav.Item>
                        </Nav>
                    </Navbar.Body>
                </Navbar>


                <Form layout="horizontal" style={{ marginTop: 10 }}>
                    <FormGroup >
                        <TextField style={{ color: "black" }} value={this.state.holder?.email} label="Email" />
                    </FormGroup>
                    <Divider></Divider>
                    <FormGroup >
                        <TextField style={{ color: "black" }} value={this.state.holder?.firstName} label="Full Name" />
                    </FormGroup>
                    <Divider></Divider>
                    <FormGroup>
                        <TextField style={{ color: "black" }} value={this.state.holder?.address} label="Address" />
                    </FormGroup>
                    <Divider></Divider>
                    <FormGroup>
                        <ControlLabel style={{ width: "200px" }} > Verified </ControlLabel>
                        {this.state.holder?.verified ? <Icon style={{ marginTop: 15, color: "green" }} icon="check" /> :
                            <Icon style={{ marginTop: 15, color: "red" }} icon="close" />}
                    </FormGroup>
                    <Divider></Divider>
                    <FormGroup>
                        <TextField style={{ color: "black" }} value={date} label="Date of Verification" />
                    </FormGroup>
                    <Divider></Divider>
                    <FormGroup>
                        <ControlLabel style={{ width: "200px" }} > Issue a new Challenge </ControlLabel>
                        <IconButton disabled={this.state.holder === undefined || this.state.holder.address === undefined || this.state.holder.address.length === 0} size="lg" icon={<Icon icon="qrcode" />} onClick={this.requestHolderChallenge} />
                    </FormGroup>
                    <Divider></Divider>
                </Form>
            </Panel>
        </>

    }
    // --------------------------------------------------------------------------------------------------

    async revokeToken(id: number): Promise<void> {
        await this.NodeService.revokeToken(id);
        this.getTokens();
    }
    // --------------------------------------------------------------------------------------------------

    handleSelect = (eventKey: number) => {
        this.setState({ activePage: eventKey })
        this.getCertificates(eventKey);
    }
    // --------------------------------------------------------------------------------------------------

    requestHolderChallenge = async () => {
        let holderAddress = this.state.holder?.address;
        if (holderAddress == null)
            return;
        let result = await this.NodeService.getHolderChallenge(holderAddress);
        this.setState({ showQRCode: true, holderChallenge: result })
    }
    // --------------------------------------------------------------------------------------------------

    getUser = async () => {
        var email = this.state.email;
        var crewId = this.state.crewId;
        if (email !== null && email !== undefined && email.length > 0) {
            //find my email
            var holders = await this.NodeService.getHolder(email!);
            if (holders !== null && holders !== undefined && holders.length > 0) {
                var holder = holders[0];
                this.setState({ holder: holder });
            }
            else {
                this.clearHolderInfo();
                this.setState({ crewId: '', email: '', name: '' });
            }
        }
        else if (crewId !== null && crewId != undefined && crewId.length > 0) {
            //find my email
            var holders = await this.NodeService.getHolderByCrewId(crewId!);
            if (holders !== null && holders !== undefined && holders.length > 0) {
                var holder = holders[0];
                this.setState({ holder: holder });
            }
            else {
                this.clearHolderInfo();
                this.setState({ crewId: '', email: '', name: '' })
            }
        }
    }
    // --------------------------------------------------------------------------------------------------

    searchUserByEmail = async () => {
        var email = this.state.email;
        //find my email

        try {
            this.clearHolderInfo();
            this.setState({ searchingUser: true, activePage: 1, totalCertificates: 0, holderCertificates: undefined })
            var holders = await this.NodeService.getHolder(email!);
            if (holders !== null && holders !== undefined && holders.length > 0) {
                var holder = holders[0];
                this.setState({ holder: holder, crewId: '', name: '', searchingUser: false });
                await this.getCertificates(1);
            }
            else {
                this.setState({ email: '', crewId: '', name: '', searchingUser: false })
            }
        }
        catch (error) {
            console.error(error)
            this.setState({ email: '', crewId: '', name: '', searchingUser: false })
        }

    }
    // --------------------------------------------------------------------------------------------------

    searchUserByCrewId = async () => {
        var crewId = this.state.crewId;
        //find my email
        try {
            this.clearHolderInfo();
            this.setState({ searchingUser: true, activePage: 1, totalCertificates: 0, holderCertificates: undefined })
            var holders = await this.NodeService.getHolderByCrewId(crewId!);
            if (holders !== null && holders !== undefined && holders.length > 0) {
                var holder = holders[0];
                this.setState({ holder: holder, email: '', name: '', searchingUser: false });
                await this.getCertificates(1);
            }
            else {
                this.setState({ email: '', crewId: '', name: '', searchingUser: false })
            }
        }
        catch (error) {
            console.error(error)
            this.setState({ email: '', crewId: '', name: '', searchingUser: false })
        }

    }
    // --------------------------------------------------------------------------------------------------

    async getCertificates(page: number) {
        if (this.state.holder !== undefined)
            // Get the list with certificates
            try {
                this.setState({ searchingCerts: true })
                let certificates = await this.NodeService.getHolderCertificates(this.state.holder.address, page);
                this.setState({ totalCertificates: certificates.totalCount, holderCertificates: certificates.result, searchingCerts: false })
            } catch (ex) {
                this.setState({ searchingCerts: false })
                console.error(ex);
            }
    }
    // --------------------------------------------------------------------------------------------------

}